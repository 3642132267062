import React from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import styled from "styled-components";
import ImageBox from "./john-motion/motion-box/image-box";
import Box from "./john-motion/motion-box/index";
import AnimatedText from "./john-motion/motion-headline";
import { Headline } from "./type";
import SvgLoader from "./svg-loader";
import Link from "./john-gatsby-helpers/link";
import { makeHover } from "../style/base-styles";

export const GridMarqueeItem = styled(Link)`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  text-align: center;
  gap: 0rem;
  transition: transform ${({ theme }) => theme.animation.duration[300].css}
    ${({ theme }) => theme.animation.timingFunction.css};
  margin: 4vw auto;

  .item-title {
    margin-bottom: -1rem;
    line-height: 1.1;
  }

  .icon-large {
    display: none;

    @media ${({ theme }) => theme.device.tablet} {
      display: inline-block;
    }
  }

  ${makeHover`
    transform: translateY(-0.5rem);

    .image-wrapper {
      transform: scale(1.05);
    }
  `}
`;

const TextWrapper = styled.div`
  border: 3px solid ${({ theme }) => theme.colors.golden};
  box-sizing: border-box;
  border-radius: 2rem;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  gap: 0;
  width: 100%;
  width: -webkit-fill-available;
  padding: 3rem;

  h4 {
    margin-bottom: 0;
  }

  @media ${({ theme }) => theme.device.tablet} {
    gap: 1rem;
  }
`;

export default function MarqueeItemRow({ uid, data }) {
  return (
    <GridMarqueeItem to={`/tour/${uid}`}>
      <TextWrapper>
        <Headline
          tag="h4"
          size="h4 item-title"
          lineLength={3}
          aria-label={data.title}
        >
          <AnimatedText text={data.title} />
        </Headline>
        <Box hiddenStyles={{ x: 0, scale: 0 }}>
          <SvgLoader className="icon-large" icon="IconLargeArrow" />
        </Box>
      </TextWrapper>
    </GridMarqueeItem>
  );
}
