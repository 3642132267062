import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import PageHeader from "../../components/page-header";
import { GridWrapper } from "../../components/layout-components";
import { device } from "../../style/theme";
import Box from "../../components/john-motion/motion-box/index";
import { makeHover } from "../../style/base-styles";
import Link from "../../components/john-gatsby-helpers/link";
import useBoardRoomData from "../../hooks/use-board-room-data";
import MarqueeItem from "../../components/marquee-item";
import MarqueeItemRow from "../../components/marquee-item-row";
import VideoSection from "../../components/video-section";
import HomepageOccasions from "../../components/homepage-occasions";
import BannerClassy from "../../components/banner-classy";
import PageHead from "../../components/globals/page-head";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function Template({
  data: {
    prismicTourIndexPage: {
      data: { title },
    },
    allPrismicTourPage: { edges },
    prismicAssets: {
      data: { main_video },
    },
    prismicHomepage: {
      data: { classy_cta_action, classy_cta_action_text, classy_cta_image },
    },
  },
}) {
  const { allPrismicTourPage } = useBoardRoomData();

  return (
    <>
      <PageHead title="Tour" />
      <PageHeader title={title} />
      <GridMarqueeWrapper>
        <GridMarquee>
          {edges.map(({ node: { uid, data } }, i) => (
            <MarqueeItem key={i} uid={uid} data={data} />
          ))}
        </GridMarquee>
        <GridMarqueeLower>
          {allPrismicTourPage?.edges.map(({ node: { uid, data } }, i) => (
            <MarqueeItemRow key={i} uid={uid} data={data} />
          ))}
        </GridMarqueeLower>
      </GridMarqueeWrapper>
      <VideoSection
        data={{
          primary: {
            preview_reel: main_video,
          },
        }}
      />
      <HomepageOccasions />
      <BannerClassy
        classy_cta_image={classy_cta_image}
        classy_cta_action_text={classy_cta_action_text}
        classy_cta_action={classy_cta_action}
      />
    </>
  );
}

const GridMarqueeWrapper = styled(GridWrapper)`
  --row-gap: 3vw;
  margin-bottom: 4vw;
`;

const GridMarquee = styled.figure`
  display: grid;
  grid-area: 1 / start-rail / 1 / end-rail;
  gap: 0.5rem;
  grid-template-rows: repeat(3, 1fr);
  position: relative;
  z-index: 1;

  @media only screen and ${device.tablet} {
    grid-area: 1 / start / 1 / end;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const GridMarqueeLower = styled.figure`
  display: grid;
  grid-area: 2 / start-rail / 2 / end-rail;
  gap: 0.5rem;
  grid-template-rows: 1fr;
  position: relative;
  z-index: 1;

  @media only screen and ${device.tablet} {
    grid-area: 2 / start-rail / 2 / end-rail;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }
`;

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query TourIndex {
    prismicTourIndexPage {
      data {
        top_level_page
        title
        nav_order
        hide_in_nav
        body {
          text
          html
          raw
        }
      }
    }

    allPrismicTourPage(
      filter: { data: { nav_order: { gte: 1 } } }
      sort: { fields: data___nav_order, order: ASC }
    ) {
      edges {
        node {
          id
          uid
          data {
            title
            nav_order
            feature_image {
              fluid(
                placeholderImgixParams: { blur: 600, px: 1 }
                maxWidth: 2000
                imgixParams: { fm: "webp" }
              ) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
          }
        }
      }
    }

    prismicAssets {
      id
      data {
        main_video {
          id
          url
        }
      }
    }

    prismicHomepage {
      data {
        classy_cta_image {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 3000
            imgixParams: { fm: "webp" }
          ) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        classy_cta_action_text
        classy_cta_action
      }
    }
  }
`;
