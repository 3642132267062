import { useStaticQuery, graphql } from "gatsby";

export const useBoardRoomData = () => {
  const data = useStaticQuery(
    graphql`
      query boardRoomDataQuery {
        allPrismicTourPage(filter: { uid: { eq: "board-room" } }) {
          edges {
            node {
              id
              uid
              data {
                title
                nav_order
                feature_image {
                  fluid(
                    placeholderImgixParams: { blur: 600, px: 1 }
                    maxWidth: 2000
                    imgixParams: { fm: "webp" }
                  ) {
                    ...GatsbyPrismicImageFluid
                  }
                  alt
                }
              }
            }
          }
        }
      }
    `
  );

  return data;
};

export default useBoardRoomData;
