import React from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import styled from "styled-components";
import ImageBox from "./john-motion/motion-box/image-box";
import Box from "./john-motion/motion-box/index";
import AnimatedText from "./john-motion/motion-headline";
import { Headline } from "./type";
import SvgLoader from "./svg-loader";
import Link from "./john-gatsby-helpers/link";
import { makeHover } from "../style/base-styles";

export const GridMarqueeItem = styled(Link)`
  display: grid;
  align-items: center;
  justify-items: center;
  text-align: center;
  gap: 2.5rem;
  transition: transform ${({ theme }) => theme.animation.duration[300].css}
    ${({ theme }) => theme.animation.timingFunction.css};

  .item-title {
    margin-bottom: -1rem;
    line-height: 1.1;
  }

  ${makeHover`
    transform: translateY(-0.5rem);

    .image-wrapper {
      transform: scale(1.05);
    }
  `}
`;

export const ImageWrapper = styled(Box)`
  border: 3px solid ${({ theme }) => theme.colors.golden};
  box-sizing: border-box;
  border-radius: 500px 500px 3.5rem 3.5rem;
  height: 25rem;
  min-height: 30vw;
  width: 100%;
  overflow: hidden;
  position: relative;

  .image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    transition: transform ${({ theme }) => theme.animation.duration[300].css}
      ${({ theme }) => theme.animation.timingFunction.css};
  }

  .image {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`;

export default function MarqueeItem({ uid, data }) {
  return (
    <GridMarqueeItem to={`/tour/${uid}`}>
      <ImageWrapper hiddenStyles={{ x: 0, y: 50 }}>
        <ParallaxBanner
          className="image-wrapper"
          layers={[
            {
              speed: -10,
              scale: [1, 1.1],
              children: (
                <ImageBox
                  className="image"
                  fluid={data.feature_image.fluid}
                  hiddenStyles={{ x: 0, y: 0, scale: 1.1 }}
                  transitionOverrides={{ duration: 3 }}
                />
              ),
            },
          ]}
        />
      </ImageWrapper>
      <Headline
        tag="h3"
        size="h4 item-title"
        lineLength={0.6}
        aria-label={data.title}
      >
        <AnimatedText text={data.title} />
      </Headline>
      <Box hiddenStyles={{ x: 0, scale: 0 }}>
        <SvgLoader icon="IconStar" />
      </Box>
    </GridMarqueeItem>
  );
}
